import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/hgw/projects/transbooks/transbooks-site/src/templates/podcast-post.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`ゲストは TRANS BOOKS DOWNLOADs に参加いただいている美術家・映像作家の荒木悠さんです！出展作品( YOUR NAME HERE ) について、そしてメディアと表現について、たっぷりとお話を伺いました。`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      